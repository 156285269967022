import _socialIcon2 from "./social-icon.js";
import _networks2 from "./networks";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SocialIcon = void 0;
Object.defineProperty(exports, "keyFor", {
  enumerable: true,
  get: function get() {
    return _networks.keyFor;
  }
});
var _socialIcon = _interopRequireDefault(_socialIcon2);
var _networks = _networks2;
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var SocialIcon = _socialIcon["default"];
exports.SocialIcon = SocialIcon;
export default exports;
export const __esModule = exports.__esModule,
  keyFor = exports.keyFor;
const _SocialIcon = exports.SocialIcon;
export { _SocialIcon as SocialIcon };